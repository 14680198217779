import React from "react"
import Avatar from "../images/avatar_nobg.png"

const Header = () => (
  <header>
    <img className="mt-md" alt="anime style avatar of a woman with black hair" src={Avatar} width="170" />
    <h2 className="is-size-1">Mari Galicer</h2>
    <h3 className="is-size-5">interdisciplinary technologist and digital security trainer</h3>
    <p className="mb-xxl">check out my <a href="https://github.com/mgalicer">code</a>, <a href="#blog-section">writing</a>, or get in contact: m@marrri.com (<a href="https://keybase.io/mvri/key.asc">pgp</a>)</p>
  </header>
)

export default Header
